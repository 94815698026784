import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import styled from 'styled-components'
import pdf from './pdf/20-1-032292 ПРОГРАММА ОБУЧАЮЩЕГО КУРСА.pdf'

const LayoutCatalog = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  position: relative;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;
  padding: 30px 1rem;
  /* background: #f2f1e2;
  border-radius: 5px;
  box-shadow: 15px 10px 40px -2px rgba(0, 0, 0, 0.8); */

  .about_wrapper {
    width: 100%;
    max-width: 580px;
  }

  h1{
    font-family: 'GT Walsheim Pro Bold';
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .about_images {
    margin-bottom: 10vw;
  }

  a:hover {
    opacity: 0.8;
  }
`

const Icon = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > span {
    padding-left: 10px;
  }

  .icon {
    display: inline-block;
    width: 40px;
    height: 50px;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    margin: 0 5px;
  }
  .icon::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-radius: 0 2px;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .icon--pdf {
    background-color: #db4437;
  }
  .icon--pdf::after {
    background: linear-gradient(45deg, #e78178 50%, #fff 50%);
  }
  .icon--pdf i::before {
    content: '☵';
  }

  .icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    font-size: 10px;
    color: #fff;
    font-weight: 500;
  }
  .icon i::before,
  .icon i::after {
    display: block;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .icon i::before {
    text-align: center;
    font-size: 12px;
    opacity: 0;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  .icon i::after {
    content: attr(title);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  &:hover .icon {
    border-radius: 2px 4px 2px 2px;
  }
  &:hover .icon::after {
    width: 12px;
    height: 12px;
  }
  &:hover .icon i::before {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  &:hover .icon i::after {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`

class EducatePage extends React.Component {
  render() {
    return (
      <Layout location="/educate">
        <LayoutCatalog>
          <Helmet title="Карьерный навигатор в сфере культурного наследия" />
          <div className="about_wrapper">
            <h1>Программа обучающего курса</h1>
            <Icon href={pdf} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>20-1-032292 ПРОГРАММА ОБУЧАЮЩЕГО КУРСА.pdf</span>
            </Icon>
          </div>
        </LayoutCatalog>
        <Footer
          text="При финансовой поддержке Фонда президентских грантов, проект №
          20-1-032292"
        />
      </Layout>
    )
  }
}

export default EducatePage
